<template>
  <div>
    <van-popup
      v-model="show"
      position="left"
      :style="{ width: '100%', height: '100%' }"
    >
      <navbar @asideShow="asideFlagFun"></navbar>
      <div class="mian">
        <div class="title">
          <van-image
            @click="closeShow"
            width="9px"
            height="15px"
            style="margin-right: 10px"
            :src="require('@/assets/cls/arrow-l.png')"
          />
          <div class="natbar" v-if="info && info.BNB">
            <svg-icon icon-class="bi" style="font-size: 20px"></svg-icon>
            {{ Number(info.BNB.amount ).toFixed(0) }}
          </div>
        </div>
        <div class="centesn">
          <div class="tabbar">
            <div
              :class="['item', active == 1 ? 'active' : '']"
              @click="toActive(1)"
            >
              {{$t("tool.miningNum")}}
            </div>
            <div
              :class="['item', active == 2 ? 'active' : '']"
              @click="toActive(2)"
            >
               {{$t("tool.miningSpeed")}}
            </div>
          </div>
            <div class="contentBox" v-if="active == 1">
              <div
                class="item"
                v-for="(item, index) in pickaxeList"
                :key="index"
              >
                <div style="display:flex;height:96%">
                  <div class="item_image">
                  <van-image
                    v-if="item.id == 1"
                    width="70px"
                    height="70px"
                    style="vertical-align: bottom"
                    :src="require('@/assets/xxImage/index/mugao.png')"
                  ></van-image>
                  <van-image
                    v-if="item.id == 2"
                    width="70px"
                    height="70px"
                    style="vertical-align: bottom"
                    :src="require('@/assets/xxImage/index/tiegao.png')"
                  ></van-image>
                  <van-image
                    v-if="item.id == 3"
                    width="70px"
                    height="70px"
                    style="vertical-align: bottom"
                    :src="require('@/assets/xxImage/index/zhuanshigao.png')"
                  ></van-image>
                </div>
                <div class="introduce">
                  <p style="font-size: 15px; font-weight: bold; color: black">
                    {{lang=='zh'?item.name:item.name_en }}
                  </p>
                  <p>{{$t("tool.toolbox")}}：{{ item.price }} UNA</p>
                  <p>{{$t("tool.AddDraft")}}：{{ (item.durable == 999 ? "∞" : item.durable + $t("tool.Addmining")) }}</p>
                  <p>{{$t("tool.card")}}：{{  Number(item.income).toFixed(4) }} BNB/{{$t("tool.Addmining")}}</p>
                </div>
                </div>
                <div
                  class="goumai"
                  v-if="UserPickaxe[index].status == '未拥有'"
                  @click="purchase(UserPickaxe[index], 1)"
                >
                  {{$t("tool.AddTools")}}
                </div>
                <div class="yiyy" v-if="UserPickaxe[index].status == '使用中'">
                  {{$t("tool.goMining")}}
                </div>
                <div
                  class="shiyon"
                  v-if="UserPickaxe[index].status == '未使用'"
                  @click="toMining(UserPickaxe[index],1)"
                >
                  {{$t("tool.Withdrawal")}}
                </div>
              </div>
            </div>
            <div class="contentBox" v-if="active == 2">
              <div class="item" v-for="(item, index) in mineCar" :key="index">
                <div style="display:flex;height:96%">
                <div class="item_image">
                  <van-image
                    v-if="item.id == 1"
                    width="70px"
                    height="70px"
                    style="vertical-align: bottom"
                    :src="require('@/assets/xxImage/index/jianyikc.png')"
                  ></van-image>
                  <van-image
                    v-if="item.id == 2"
                    width="70px"
                    height="70px"
                    style="vertical-align: bottom"
                    :src="require('@/assets/xxImage/index/putongkc.png')"
                  ></van-image>
                  <van-image
                    v-if="item.id == 3"
                    width="70px"
                    height="70px"
                    style="vertical-align: bottom"
                    :src="require('@/assets/xxImage/index/gaojikc.png')"
                  ></van-image>
                </div>
                <div class="introduce">
                  <p style="font-size: 15px; font-weight: bold; color: black">
                     {{lang == 'zh'?item.name:item.name_en }}
                  </p>
                  <p>{{$t("tool.miningz")}}：{{ item.price }} UNA</p>
                  <p>{{$t("tool.prop")}}：{{item.load}}BNB</p>
                </div>
                </div>
                <div
                  class="goumai"
                  v-if="item.status == '未拥有'"
                  @click="purchase(item, 2)"
                >
                  {{$t("tool.AddTools")}}
                </div>
                <div class="yiyy" v-if="item.status == '使用中'">{{$t("tool.goMining")}}</div>
                <div
                  class="shiyon"
                  v-if="item.status == '未使用'"
                  @click="toMining(item,2)"
                >
                  {{$t("tool.Withdrawal")}}
                </div>
              </div>
            </div>
        </div>
        <!-- 购买弹框 -->
        <van-dialog
          v-model="showDialog"
          title="提示"
          :showConfirmButton="false"
        >
          <div class="usdtMg">
            <p>{{$t("tool.Successfully")}}{{lang=='zh'? purchasePickaxe.name:purchasePickaxe.name_en }}{{$t("tool.redeem")}}？</p>
            <p style="color: #7857fe; margin-top: 10px">
              {{$t("tool.miningz")}}:{{ purchasePickaxe.price }} UNA
            </p>
            <div class="buttons">
              <div @click="showDialog = false">{{$t("tool.cancellation")}}</div>
              <div
                @click="determineBus"
                style="margin-left: 12px; background: #8f93fb; color: white"
              >
                {{$t("tool.determine")}}
              </div>
            </div>
          </div>
        </van-dialog>
        <!-- 替换弹框 -->
        <van-dialog
          v-model="showDialog2"
          :title="$t('tool.empty')"
          :showConfirmButton="false"
        >
          <div class="usdtMg">
            <p>
              {{$t("tool.hours")}}
              <span style="color: #7857fe">{{lang=='zh'? purchasePickaxe.name:purchasePickaxe.name_en }}</span> ？
            </p>
            <div class="buttons">
              <div @click="showDialog2 = false">{{$t("tool.cancellation")}}</div>
              <div
                @click="yestoMining()"
                style="margin-left: 12px; background: #8f93fb; color: white"
              >
                {{$t("tool.determine")}}
              </div>
            </div>
          </div>
        </van-dialog>
      </div>
      <asidePopup
        v-if="asideFlag"
        @close="noShow"
        :shows="asideFlag"
      ></asidePopup>
    </van-popup>
  </div>
</template>

<script>
import asidePopup from "@/components/common/asidePopup";
export default {
  components: { asidePopup },

  data() {
    return {
      showDialog3: false,
      showDialog2: false, //替換
      showDialog: false,
      show: true,
      asideFlag: false,
      active: 1,
      mgText: "",
      Draft: null,
      pickaxeList: [],
      UserPickaxe: {},
      purchasePickaxe: {},
      mineCar: [],
      type: null,
      type2:null,
      info:null,
    };
  },
  created() {
    this.getUserInfo()
    this.getUserPickaxe();
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
  },
  methods: {
    getUserInfo(){
      this.$axios.get(this.$api.myEarn).then((res) => {
        this.info = res.data
      });
    },
    //用户矿车数据
    getMineCart() {
      this.$axios.get(this.$api.userMineCart).then((res) => {
        this.mineCar = res.data;
      });
    },
    //确定购买搞头、矿车
    determineBus() {
      if (this.type == 1) {
        this.$axios
          .post(this.$api.buyPick, { id: this.purchasePickaxe.id })
          .then((res) => {
            this.$toast(this.$t('tool.addToolse'));
            this.getUserPickaxe();
            this.showDialog = false;
          });
      }

      if (this.type == 2) {
        this.$axios
          .post(this.$api.buyMineCart, { id: this.purchasePickaxe.id })
          .then((res) => {
            this.$toast(this.$t('tool.addToolse'));
            this.getUserPickaxe();
            this.showDialog = false;
          });
      }
    },
    //购买搞头、矿车
    purchase(option, type) {
      this.type = type;
      this.showDialog = true;
      this.purchasePickaxe = option;
    },
    //获取搞头数据
    getPickaxe() {
      this.$axios.get(this.$api.getpickaxe).then((res) => {
        this.pickaxeList = res.data;
      });
    },
    //用户搞头数据
    getUserPickaxe() {
      this.$axios.get(this.$api.userPick).then((res) => {
        this.UserPickaxe = res.data;
        this.getPickaxe();
        this.getMineCart();
      });
    },
    //替换搞头、矿车
    toMining(option,type) {
      this.type2 = type
      this.purchasePickaxe = option;
      this.showDialog2 = true;
    },
    //确认替换搞头、矿车
    yestoMining() {
      if(this.type2 == 1){
        this.$axios
        .post(this.$api.usePrick, {
          user_pickid: this.purchasePickaxe.userPickId,
        })
        .then((res) => {
          this.$emit("pick");
          this.closeShow();
        });
      }

      if(this.type2 == 2){
        this.$axios
        .post(this.$api.useMineCart, {
          user_minecartid: this.purchasePickaxe.userMineCartId,
        })
        .then((res) => {
          this.$emit("pick");
          this.closeShow();
        });
      }
    },
    //使用稿头
    useDraft(text) {
      this.showDialog2 = true;
      this.mgText = text;
      if (text == "铁镐") {
        this.Draft = {
          label: text,
          icon: require("@/assets/xxImage/index/tiegao.png"),
        };
      }
    },
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
    closeShow() {
      this.$emit("closeShow");
    },
    toActive(num) {
      this.active = num;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  .cell-title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
.mian {
  padding: 10px;
  .title {
    display: flex;
    color: #000;
    align-items: center;
    .natbar {
      background: #f2f2f2;
      width: 71px;
      height: 31px;
      border: 1px solid #000;
      border-radius: 50px;
      text-align: center;
      line-height: 31px;
    }
  }
  .centesn {
    .tabbar {
      width: 201px;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #000;
      border-radius: 50px;
      margin: 0 auto;
      .item {
        width: 100px;
        height: 36px;
        border-radius: 50px;
        text-align: center;
        line-height: 36px;
      }
    }
    .content {
      width: 100%;
      height: 380px;
      border-bottom: 1px solid #000;
      border-radius: 20px;
      background: #7857fe;
    }
    .contentBox {
      background: white;
      width: 100%;
      height: 372px;
      border: 1px solid #000;
      border-radius: 20px;
      padding: 27px 11px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 1px 1px 0px 0px #000000;
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item_image {
          width: 98px;
          background: #eff4f8;
          height: 94px;
          border: 1px solid #000;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .introduce {
          height: 100%;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            white-space: nowrap;
          }
        }
        .yiyy {
          width: 87px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #000000;
          box-shadow:inset 0 0px 5px rgba(255, 255, 255, 1);
          border-radius: 5px;
          text-align: center;
          line-height: 32px;
          // margin-left: 50px;
        }
        .shiyon {
          width: 87px;
          height: 32px;
          background: #e6a23c;
          border: 1px solid #000000;
          box-shadow:inset 0 0px 5px rgba(255, 255, 255, 1);
          border-radius: 5px;
          text-align: center;
          line-height: 32px;
          // margin-left: 50px;
          color: white;
        }
        .goumai {
          width: 87px;
          height: 32px;
          background: #8f93fb;
          border: 1px solid #000000;
          box-shadow:inset 0 0px 5px rgba(255, 255, 255, 1);
          border-radius: 5px;
          text-align: center;
          line-height: 32px;
          // margin-left: 50px;
          color: white;
        }
      }
    }
    .active {
      background: #8f93fb;
      color: white;
    }
  }
}
.usdtMg {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  p {
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 26px;
    div {
      flex: 0.4;
      height: 32px;
      border: 1px solid #000;
      border-radius: 5px;
      text-align: center;
      line-height: 30px;
      color: black;
      font-size: 12px;
    }
  }
}
/deep/ .van-dialog {
  width: 236px;
  // height: 156px;
  border: 1px solid #000;
  border-radius: 10px;
}
/deep/ .van-dialog__header {
  padding-top: 10px;
}
</style>