<template>
  <div
    class="content containers"
    style="
      min-height: 100vh;
      background: linear-gradient(90deg, #b79afa, #8867fe);
    "
  >
    <navbar @asideShow="asideFlagFun"></navbar>
    <div class="main">
      <tool
        @pick="pickTwo"
        v-if="closeShow"
        @closeShow="closeShow = false"
      ></tool>
      <div class="mining">
        <van-image
          width="100%"
          height="250px"
          style="vertical-align: bottom"
          :src="require('@/assets/xxImage/index/08.jpg')"
        ></van-image>
        <div class="centesr">
          <div class="outside">
            <div class="outside_item">
              <svg-icon icon-class="bi" style="font-size: 25px"></svg-icon>
              <div class="text">
                <p>{{ userInfo.mine_num }}BNB</p>
                <span>{{ $t("mining.miningNum") }}</span>
              </div>
            </div>
            <div class="outside_item">
              <svg-icon icon-class="sandian" style="font-size: 25px"></svg-icon>
              <div class="text">
                <p>≈{{ miningSpeedDisplay }}U/D</p>
                <span>{{ $t("mining.miningSpeed") }}</span>
              </div>
            </div>
          </div>
          <div class="function">
            <div class="toolbox">
              <div class="title">
                <p>{{ $t("mining.toolbox") }}</p>
                <!-- <svg-icon
                    icon-class="yiwen"
                    style="font-size: 15px"
                  ></svg-icon> -->
                <van-popover class="titleMode" v-model="showPopover" trigger="click" :show-arrow="false">
                  <div
                    style="
                      background: #ffffff;
                      box-shadow: 0px 0px 4px 0px rgba(180, 180, 180, 0.5);
                      border-radius: 10px;
                      font-size: 12px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #333333;
                      line-height: 18px;
                      padding: 14px 20px;
                      border: 1px solid #000;
                    "
                  >
                    {{ $t("mining.reinvestmentText") }}
                  </div>
                  <template #reference>
                    <div>
                      <van-image
                        width="15px"
                        height="15px"
                        :src="require('@/assets/cls/question.png')"
                      />
                    </div>
                  </template>
                </van-popover>
              </div>
              <div class="selection">
                <div class="draft">
                  <div
                    @click="toTool"
                    style="width: 100%"
                    class="draftIf"
                    v-if="!Draft.text"
                  >
                    <p style="margin: 3px 0 25px 6px">
                      {{ $t("mining.AddDraft") }}
                    </p>
                    <van-image
                      width="25px"
                      height="25px"
                      :src="require('@/assets/xxImage/index/tianjia.png')"
                    ></van-image>
                  </div>
                  <div v-else @click="toTool" class="draftIf">
                    <p style="margin: 3px 0 10px 6px">{{ Draft.text }}</p>
                    <van-image
                      width="58px"
                      height="58px"
                      :src="Draft.icon"
                    ></van-image>
                    <div class="progress" style="position: absolute; bottom: 0">
                      <div style="margin-bottom: 2px">{{ Draft.speed }}/D</div>
                      <van-progress
                        v-if="flag"
                        track-color="white"
                        color="#67C23A"
                        :show-pivot="false"
                        :percentage="draftjd"
                        stroke-width="4"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="draft" style="width: 84px; border: none">
                    <div class="mine" @click="toTool" v-if="!mineCart.text">
                      <p>{{ $t("mining.Addmining") }}</p>
                      <van-image
                        width="25px"
                        height="25px"
                        :src="require('@/assets/xxImage/index/tianjia.png')"
                      ></van-image>
                    </div>
                    <div
                      v-else
                      class="draftIf"
                      @click="toTool"
                      style="
                        border: 1px solid #000;
                        border-radius: 10px;
                        height: 110px;
                      "
                    >
                      <p style="margin: 3px 0 10px 6px">
                        {{ mineCart.text }}
                      </p>
                      <van-image
                        width="58px"
                        height="58px"
                        :src="mineCart.icon"
                      ></van-image>
                      <div
                        class="progress"
                        style="position: absolute; bottom: 0"
                      >
                        <div style="margin-bottom: 2px">
                          {{ jdwcCord ? jdwcCord : "0" }}/10
                        </div>
                        <van-progress
                          v-if="flag"
                          track-color="white"
                          color="#67C23A"
                          :show-pivot="false"
                          :percentage="wcCord"
                          stroke-width="4"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="title">
                <p>{{ $t("mining.card") }}</p>
              </div>
              <div class="selection" @click="determine">
                <div class="draft" v-if="userInfo.is_auto == 2">
                  <van-image
                    width="25px"
                    height="25px"
                    :src="require('@/assets/xxImage/index/tianjia.png')"
                  ></van-image>
                </div>
                <div class="draft" v-if="userInfo.is_auto == 1">
                  <van-image
                    width="61px"
                    height="77px"
                    style="vertical-align: bottom; margin-top: 2px"
                    :src="require('@/assets/xxImage/index/jiangli.png')"
                  >
                  </van-image>
                  <div class="aotu">{{ $t("mining.AutomaticMining") }}</div>
                </div>
              </div>
            </div>
          </div>
          <p class="bottom_title" v-if="!Draft.text || !mineCart.text">
            {{ $t("mining.AddTools") }}
          </p>
          <!-- 开始挖矿 -->
          <div class="fgoMining" v-if="userInfo.is_mine == 3">
            <div
            class="goMining"
            @click="startMining"
          >
            {{ $t("mining.goMining") }}
          </div>
          <div style="width:10px"></div>
          <div
            :style="userInfo.mine_num &&userInfo.mine_num > 0?'background:#9777ff':'background:#b6a7fe;border:1px solid #ccc'"
            class="sgoMining"
            @click="emptymineCart"
          >
            {{ $t("mining.withdraslm") }}
          </div>
          </div>
          
          <!-- 矿车已满 提现到资产 -->
          <div
            class="goMining"
            v-if="userInfo.is_mine == 2"
            @click="emptymineCar"
          >
            {{ $t("mining.Withdrawal") }}
          </div>
          <!-- 挖矿中 -->
          <div
            class="goMining"
            style="background: #e6a23c"
            v-if="userInfo.is_mine == 1"
          >
            {{ $t("mining.miningz") }} {{ time }}
          </div>
        </div>
      </div>
      <asidePopup
        v-if="asideFlag"
        @close="noShow"
        :shows="asideFlag"
      ></asidePopup>
      <!-- 道具弹框 -->
      <van-dialog
        v-model="showDialog3"
        :showConfirmButton="false"
        style="width: 236px"
      >
        <div class="usdtMgTwo">
          <div>
            <div class="title">
              <p>{{ $t("mining.prop") }}</p>
              <p
                style="color: #666666; font-size: 20px"
                @click="showDialog3 = false"
              >
                ×
              </p>
            </div>
          </div>
          <div class="card_center">
            <div class="card_image">
              <p>
                × <span>{{ card }}</span>
              </p>
              <van-image
                width="61px"
                height="77px"
                style="vertical-align: bottom; margin-top: 2px"
                :src="require('@/assets/xxImage/index/jiangli.png')"
              >
              </van-image>
            </div>
            <div class="card_right">
              <p>{{ $t("mining.card") }}</p>
              <p>{{ $t("mining.effect") }}</p>
              <p>{{ $t("mining.source") }}</p>
              <div
                class="card_btn"
                @click="$router.push('/answer')"
                v-if="card == 0"
              > 
               {{ $t("mining.get") }}
              </div>
              <div
                class="card_btn"
                style="background: #8f93fb"
                v-else
                @click="useCard"
              >
                {{ $t("mining.use") }}
              </div>
            </div>
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import tool from "@/components/tool.vue";
import asidePopup from "@/components/common/asidePopup";
import BigNumbers from "bignumber.js";


export default {
  components: { tool, asidePopup },
  data() {
    return {
      active:false,
      showPopover: false,
      showDialog3: false,
      closeShow: false,
      asideFlag: false,
      DraftList: [],
      Draft: {},
      mineCart: {},
      card: null,
      userInfo: {},
      time: "",
      objTimes: null,
      draftjd: 0,
      wcCord: 0,
      jdwcCord: null,
      flag: false, //解决created异步 数据延迟报错问题
    };
  },
  created() {
    this.getDraft();
    this.getMineCart();
    this.getuserInfo();
    this.getUserMine();
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
    miningSpeedDisplay () {
      if (this.userInfo != null && this.userInfo.pick != null && typeof this.userInfo.pick.bnbprice != 'undefined' && this.Draft != null && typeof this.Draft.speed != 'undefined') {
        return BigNumbers(this.userInfo.pick.bnbprice).times(this.Draft.speed).toFixed(1)      
      } else {
        return 0
      }
    }
  },
  methods: {
    // 开始挖矿中情况矿车
    emptymineCart() {
      if(this.userInfo.mine_num && this.userInfo.mine_num>0){
        this.emptymineCar()
      }
    },
    //清空矿车
    emptymineCar() {
      this.$axios.post(this.$api.minecartClean).then((res) => {
        this.$toast(this.$t("mining.empty"));
        this.getUserMine();
      });
    },
    //执行倒计时
    getTimes() {
      this.dval = this.userInfo.remaining_time;
      this.objTimes = setInterval(() => {
        this.min_timeDown(this.dval);
      }, 1000);
    },
    //正在挖矿倒计时
    min_timeDown(val) {
      this.dval--;
      // const endTime = new Date(val);
      // const nowTime = new Date();
      // let leftTime = parseInt((endTime.getTime() - nowTime.getTime()) / 1000);
      let d = parseInt(this.dval / (24 * 60 * 60));
      // let h = formate(parseInt((this.dval / (60 * 60)) % 24));
      let h = formate(parseInt(this.dval / (60 * 60)));
      let m = formate(parseInt((this.dval / 60) % 60));
      let s = formate(parseInt(this.dval % 60));
      if (this.dval <= 0) {
        // this.time = "倒计时已完成";
        clearInterval(this.objTimes);
        this.getUserMine();
      } else {
        this.time = `${h}:${m}:${s}`; // 需要修改时间样式的话 ，比如需要什么30分钟倒计时，就只保留分和秒
      }
      function formate(time) {
        if (time >= 10) {
          return time;
        } else {
          return `0${time}`;
        }
      }
    },
    //开始挖矿
    startMining() {
      this.$axios.post(this.$api.mineStart).then((res) => {
        this.$toast(this.$t("mining.goMining"));
        this.getUserMine();
      });
    },
    //用户挖矿数据
    getUserMine() {
      this.$axios.get(this.$api.userMine).then((res) => {
        this.userInfo = res.data;
        this.draftjd = Math.floor(
          (Number(this.userInfo.pick.durableSurplus) /
            Number(this.userInfo.pick.durableTotal)) *
            100
        );
        this.wcCord = Math.floor(
          (Number(this.userInfo.mineCart.loaded) /
            Number(this.userInfo.mineCart.capacity)) *
            100
        );
        this.jdwcCord = Math.floor(
          (Number(this.userInfo.mineCart.loaded) /
            Number(this.userInfo.mineCart.capacity)) *
            10
        );
        if (this.userInfo.is_mine == 1) {
          this.getTimes();
        }
        this.flag = true;
      });
    },
    //使用挖矿卡
    useCard() {
      this.$axios.post(this.$api.useAutocard).then((res) => {
        this.showDialog3 = false;
        this.$toast(this.$t("mining.Successfully"));
        this.getUserMine();
        this.getuserInfo();
      });
    },
    //获取用户信息
    getuserInfo() {
      this.$axios.get(this.$api.userInfo).then((res) => {
        this.card = res.data.miningCardNum;
      });
    },
    //开始挖矿
    goMining() {
      this.$axios.post(this.$api.mineStart).then((res) => {});
    },
    //自动挖矿卡
    determine() {
      if (this.card == 0) {
        this.showDialog3 = true;
      }
      this.showDialog3 = true;
    },
    //用户矿车数据
    getMineCart() {
      this.$axios.get(this.$api.userMineCart, {}).then((res) => {
        res.data.forEach((item) => {
          if (item.status == "使用中") {
            this.mineCart.text = this.lang == "zh" ? item.name : item.name_en;
            this.mineCart.speed = item.income;
            if (item.name == "簡易礦車") {
              this.mineCart.icon = require("@/assets/xxImage/index/jianyikc.png");
            }
            if (item.name == "普通礦車") {
              this.mineCart.icon = require("@/assets/xxImage/index/putongkc.png");
            }
            if (item.name == "高級礦車") {
              this.mineCart.icon = require("@/assets/xxImage/index/gaojikc.png");
            }
          }
        });
        this.$forceUpdate(); //强制视图更新
      });
    },
    //用户镐头数据
    getDraft() {
      this.$axios.get(this.$api.userPick, {}).then((res) => {
        res.data.forEach((item) => {
          if (item.status == "使用中") {
            this.Draft.text = this.lang == "zh" ? item.name : item.name_en;
            this.Draft.speed = Number(item.income).toFixed(4);
            if (item.name == "木鎬") {
              this.Draft.icon = require("@/assets/xxImage/index/mugao.png");
            }
            if (item.name == "鐵鎬") {
              this.Draft.icon = require("@/assets/xxImage/index/tiegao.png");
            }
            if (item.name == "鑽石鎬") {
              this.Draft.icon = require("@/assets/xxImage/index/zhuanshigao.png");
            }
          }
        });
        this.$forceUpdate(); //强制视图更新
      });
    },
    pickTwo(e) {
      this.getDraft();
      this.getMineCart();
    },
    toTool() {
      this.closeShow = true;
    },
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  padding: 13px 14px;
  .bottom_border {
    border-bottom: 1px solid #000;
    border-radius: 10px;
    background: #8f93fb;
    padding-bottom: 10px;
  }
  .mining {
    border: 1px solid solid;
    border-radius: 10px;
    overflow: hidden;
  }
  .centesr {
    padding: 14px 21px;
    background: #e0e1ff;
    border: 1px solid #000;
    border-radius: 0 0 10px 10px;
    .outside {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .outside_item {
        width: 148px;
        height: 50px;
        border: 1px solid #000;
        border-radius: 10px;
        background: white;
        display: flex;
        align-items: center;
        padding: 0 15px;
        display: flex;
        justify-content: space-around;
        .text {
          margin-left: 10px;
          p {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          }
          span {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
    .function {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 17px;
      .card {
        width: 110px;
        height: 152px;
        border: 1px solid #000;
        border-radius: 10px;
        padding: 12px;
        background: white;
        .title {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          }
        }
        .selection {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          .draft {
            width: 80px;
            height: 109px;
            border: 1px solid #000;
            background: #eff4f8;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 3px;
          }
          .aotu {
            position: absolute;
            bottom: 0;
            width: 95%;
            border-radius: 0 0 10px 10px;
            height: 25px;
            background: rgba(0, 0, 0, 0.4);
            text-align: center;
            line-height: 25px;
            font-size: 10px;
            color: white;
          }
        }
      }
      .toolbox {
        width: 187px;
        height: 152px;
        border: 1px solid #000;
        border-radius: 10px;
        padding: 12px;
        background: white;
        .title {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          }
        }
        .selection {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          .draft {
            position: relative;
            width: 67px;
            height: 109px;
            border: 1px solid #000;
            background: #eff4f8;
            border-radius: 10px;
            // padding: 3px;
            .draftIf {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              .progress {
                width: 100%;
                height: 24px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 0px 0px 10px 10px;
                padding: 2px 5px;
                box-sizing: border-box;
                color: white;
                font-size: 10px;
                font-family: Source Han Sans CN;
                text-align: right;
              }
            }
            p {
              width: 100%;
              font-size: 11px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              margin-bottom: 25px;
            }
          }
          .mine {
            width: 84px;
            height: 109px;
            border: 1px solid #000;
            background: #eff4f8;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3px;
            p {
              width: 100%;
              font-size: 11px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              margin-bottom: 25px;
            }
          }
        }
      }
    }
    .bottom_title {
      width: 100%;
      text-align: center;
      margin: 25px 0 10px 0;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
    }
  }
}

.list {
  .cell-title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
.goMining {
  width: 100%;
  height: 37px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #8f93fb;
  text-align: center;
  color: #ffffff;
  line-height: 35px;
  margin-top: 25px;
  box-shadow: inset 0 0px 5px rgba(255, 255, 255, 1);
  font-weight: 500;
}
.usdtMgTwo {
  width: 240px;
  // height: 180px;
  text-align: center;
  padding: 15px 15px;
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    p:nth-of-type(1) {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
  }
  .card_center {
    display: flex;
    margin-top: 17px;
    .card_image {
      width: 90px;
      height: 104px;
      border: 1px solid #000;
      border-radius: 5px;
      background: #eff4f8;
      padding: 0 5px;
      box-sizing: border-box;
      p {
        width: 100%;
        text-align: right;
        color: #7857fe;
        font-size: 15px;
      }
    }
    .card_right {
      margin-left: 10px;
      p {
        font-size: 11px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #333333;
        text-align: left;
        margin-bottom: 10px;
        // white-space: nowrap;
      }
      .card_btn {
        width: 87px;
        height: 32px;
        border: 1px solid #000;
        border-radius: 5px;
        background: #61bfbf;
        color: white;
        line-height: 28px;
        font-size: 12px;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 26px;
    div {
      flex: 0.4;
      height: 32px;
      border: 1px solid #000;
      border-radius: 50px;
      text-align: center;
      line-height: 30px;
      color: black;
      font-size: 12px;
    }
  }
}
.fgoMining{
  width: 100%;
  display: flex;

}
.sgoMining{
  width: 100%;
  height: 37px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #9777ff;
  text-align: center;
  color: #ffffff;
  line-height: 35px;
  margin-top: 25px;
  box-shadow: inset 0 0px 5px rgba(255, 255, 255, 1);
  font-weight: 500;
}
  .title /deep/ .van-popup /deep/ .van-popover__arrow{
    display: none !important;
  }
</style>